*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-size: 10px;
  font-family: "Systems UI", Helvetica, "Sans Serif";
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

#wrapper {
  width: 100vw;
  height: 100vh;
  background: #fcf3f1;

  canvas {
    width: 100%;
    height: 100%;
  }
}

.block-content {
  // border: 1px solid rgb(252, 243, 241);
  // color: rgb(252, 243, 241);
  color: #333;
  font-size: 2rem;
  display: block;
  // background: #333;
  text-align: left;
  // min-width: 50px;
  // max-width: 150px;
  height: auto;
  z-index: 1;
  position: absolute;
  padding: 10px;
  opacity: 1;
  width: 70vw;
}

.block-margin {
  // width: 500px;
  background: rgba(0, 0, 255, 0.05);
  color: rgba(0, 0, 0, 0.2);
  // padding-top: 20px;
  height: 4rem;

  p {
    font-family: Menlo, monospace;
    font-size: 2rem;
  }
}

.block-padding {
  // width: 500px;
  background: rgba(0, 255, 0, 0.05);
  color: rgba(0, 0, 0, 0.2);
  // padding-top: 20px;
  height: 6rem;

  p {
    font-family: Menlo, monospace;
    font-size: 2rem;
  }
}

a {
  color: red;
  background: #fafafa;
  text-decoration: none;
}
canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: 0;
}
canvas:hover {
  opacity: 0;
}
#wrapper p {
  position: absolute;
  font-style: oblique;
  line-height: 14px;
  margin: 0;
  padding: 0;
  color: red;
  font-size: 10px;
}
.block.small {
  background: gold;
}
